import React from 'react'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
import { MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSitemap } from '@fortawesome/pro-regular-svg-icons'


class RelatedProds extends React.Component {
  render() {
    return (
      <MDBCol
        lg={this.props.collg}
        md={this.props.colmd}
        className="pb-30 d-flex"
      >
        <div className="card promoting-card flex-fill">
          <div
            className="card-body d-flex flex-row"
            style={{ minHeight: '110px', maxHeight: '110px' }}
          >
            <Img
              fixed={this.props.image}
              className="rounded-circle mr-3"
              alt="company logo"
            />

            <div>
              <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-large text-large">
                {this.props.title}
              </h3>
              <p className="card-text">
                <FontAwesomeIcon
                  icon={faSitemap}
                  className="mr-1 unicom-blue"
                />
                {this.props.suite}
              </p>
            </div>
          </div>

          <div className="card-body-prods">
            <p className="text-medium text-gray-dark">
              {this.props.description}
            </p>
          </div>

          <div className="card-footer">
            {this.props.link !== null && (
              <div className="text-center">
                <Link
                  to={this.props.link}
                  className="btn btn-block btn-md btn-mdb-color"
                >
                  <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                  {this.props.linktext}
                </Link>
              </div>
            )}
          </div>
        </div>
      </MDBCol>
    )
  }
}

export default RelatedProds
